import React from 'react';

export default function Pagination({ elements, elementsPerPage, currentPage, handlePagnination }) {
	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(elements.length / elementsPerPage); i++) {
		pageNumbers.push(i);
	}

	return (
		<>
			{pageNumbers.length > 1 && (
				<div className="pagination--wrapper">
					<nav aria-label="Pagination Navigation" id="page-numbers">
						{pageNumbers.map((number) => {
							return (
								<li
									key={number}
									id={number}
									aria-label={`${
										number !== currentPage ? `Goto Page ${number}` : `Current Page, page ${number}`
									}`}
									aria-current={number === currentPage}
									onClick={handlePagnination}
								>
									{number}
								</li>
							);
						})}
					</nav>
				</div>
			)}
			<style jsx>
				{`
					.pagination--wrapper {
						display: flex;
						justify-content: center;
						align-items: center;
					}
					nav {
						list-style: none;
						display: flex;
						flex-flow: row wrap;
					}
					li {
						margin: 5px;
						padding: 10px;
						cursor: pointer;
					}
					li[aria-current='true'] {
						color: #89a112;
						cursor: default;
					}
				`}
			</style>
		</>
	);
}
