import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import MakeHtml from '../components/commons/MakeHtml';
import IconArrow from '../components/commons/IconArrow';
import Pagination from '../components/commons/Pagination';

export function BlogPageTemplate({ nodes }) {
	const [currentPage, setCurrentPage] = useState(1);
	const elementsPerPage = 10;

	const handlePagnination = (event) => {
		setCurrentPage(parseInt(event.target.id, 10));
	};

	const indexOfLastElement = currentPage * elementsPerPage;
	const indexOfFirstElement = indexOfLastElement - elementsPerPage;
	const currentElements = nodes
		.sort((a, b) => {
			a = new Date(a.frontmatter.date.split(' ').reverse().join('-'));
			b = new Date(b.frontmatter.date.split(' ').reverse().join('-'));
			return b - a;
		})
		.slice(indexOfFirstElement, indexOfLastElement);

	return (
		<>
			<div>
				<h1>Actualités</h1>
			</div>
			<div className="blog-wrapper small-contain">
				{currentElements.map(({ frontmatter }) => (
					<div className="blog-article-wrapper">
						<picture>
							<source
								srcSet={
									!!frontmatter.actuThumbnail.image.childImageSharp
										? frontmatter.actuThumbnail.image.childImageSharp.fluid.srcWebp
										: frontmatter.actuThumbnail.image
								}
								type="image/webp"
							/>
							<img
								src={
									!!frontmatter.actuThumbnail.image.childImageSharp
										? frontmatter.actuThumbnail.image.childImageSharp.fluid.src
										: frontmatter.actuThumbnail.image
								}
								alt={frontmatter.actuThumbnail.altImage}
								loading="lazy"
							/>
						</picture>
						<div className="blog-article-text">
							<p className="blog-article-date">{frontmatter.date}</p>
							<h2 className="blog-article-title">{frontmatter.title}</h2>
							<MakeHtml text={frontmatter.introduction} />
							<div className="blog-article-link-wrapper">
								<a
									href={frontmatter.link}
									className="blog-article-link"
									target="_blank"
									rel="nofollow noopener"
								>
									<IconArrow />
								</a>
							</div>
						</div>
					</div>
				))}
				<Pagination
					elements={nodes}
					elementsPerPage={elementsPerPage}
					currentPage={currentPage}
					handlePagnination={handlePagnination}
				/>
			</div>

			<style jsx>{`
				.blog-article-wrapper {
					display: flex;
					flex-flow: row wrap;
					align-items: center;
					justify-content: space-between;
					margin: 60px 20px;
				}
				.blog-article-text {
					width: calc(100% - 400px);
					min-width: 340px;
				}
				.blog-article-date {
					font-size: 14px;
					margin: 0;
				}
				.blog-article-title {
					color: #89a112;
					margin-top: 10px;
				}
				.blog-article-link-wrapper {
					text-align: right;
				}
				@media (max-width: 850px) {
					.blog-article-wrapper {
						display: flex;
						flex-direction: column;
						justify-content: center;
					}
				}
			`}</style>
		</>
	);
}

export default function BlogPage({ data }) {
	const { nodes } = data.allMarkdownRemark;

	return (
		<Layout
			title="Actualités | ECEO"
			description="Retouvez la liste des actualités de ECEO, votre partenaire en excellence opérationnelle"
		>
			<BlogPageTemplate nodes={nodes} />
		</Layout>
	);
}

export const pageQuery = graphql`
	query BlogPage {
		allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "actu-page" } } }) {
			nodes {
				frontmatter {
					title
					date
					actuThumbnail {
						altImage
						image {
							childImageSharp {
								fluid(maxWidth: 360, quality: 90) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					introduction
					link
				}
			}
		}
	}
`;
