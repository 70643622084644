import React from 'react';
import showdown from 'showdown';

export default function MakeHtml({ text }) {
	const converter = new showdown.Converter();
	const textHtml = converter.makeHtml(text);
	return (
		<>
			<div className="markdown" dangerouslySetInnerHTML={{ __html: textHtml }} />
		</>
	);
}
